import { FC, SVGProps } from "react";

export const OrderEmpty: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" width={18} {...props}>
        <path
            d="M8.39999 16.8333C6.75181 16.8333 5.14065 16.3446 3.77024 15.4289C2.39983 14.5132 1.33172 13.2117 0.700991 11.689C0.0702611 10.1663 -0.0947667 8.49072 0.226777 6.87421C0.54832 5.2577 1.34199 3.77284 2.50743 2.60741C3.67287 1.44197 5.15773 0.648296 6.77424 0.326752C8.39074 0.00520889 10.0663 0.170237 11.589 0.800967C13.1117 1.4317 14.4132 2.4998 15.3289 3.87021C16.2446 5.24062 16.7333 6.85179 16.7333 8.49996C16.7333 10.7101 15.8553 12.8297 14.2925 14.3925C12.7297 15.9553 10.6101 16.8333 8.39999 16.8333ZM8.39999 1.8333C7.08145 1.8333 5.79251 2.22429 4.69619 2.95683C3.59986 3.68938 2.74537 4.73057 2.24079 5.94874C1.73621 7.16691 1.60418 8.50736 1.86142 9.80056C2.11865 11.0938 2.75359 12.2817 3.68594 13.214C4.61829 14.1464 5.80618 14.7813 7.09939 15.0385C8.39259 15.2958 9.73304 15.1637 10.9512 14.6592C12.1694 14.1546 13.2106 13.3001 13.9431 12.2038C14.6757 11.1074 15.0667 9.81851 15.0667 8.49996C15.0667 6.73185 14.3643 5.03616 13.114 3.78592C11.8638 2.53568 10.1681 1.8333 8.39999 1.8333Z"
            fill="#31D97C"
        />
    </svg>
);
