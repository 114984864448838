import LogoIcon from "assets/images/logo.png";

import { FC } from "react";
import { Link } from "react-router-dom";
import { HOME_PATH } from "router/constants";
import { twMerge } from "tailwind-merge";

interface Props {
    className?: string;
}

export const Logo: FC<Props> = ({ className }) => (
    <Link to={HOME_PATH} className={twMerge("flex items-center", className)}>
        <img src={LogoIcon} alt="Logo" />

        <span className="font-bold text-xs ml-2 text-tuscany">
            {process.env.REACT_APP_WEBSITE_TITLE}
        </span>
    </Link>
);
