import { FC, SVGProps } from "react";

export const PocketIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" width={28} {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.6166 9.33798C24.5525 9.33331 24.4813 9.33331 24.4066 9.33331H21.4608C19.0481 9.33331 16.9843 11.2326 16.9843 13.7083C16.9843 16.184 19.0493 18.0833 21.4608 18.0833H24.4066C24.4813 18.0833 24.5525 18.0833 24.619 18.0786C25.1148 18.0487 25.5829 17.8396 25.936 17.4902C26.2891 17.1407 26.5031 16.6748 26.5381 16.1793C26.5428 16.1093 26.5428 16.0335 26.5428 15.9635V11.4531C26.5428 11.3831 26.5428 11.3073 26.5381 11.2373C26.5031 10.7418 26.2891 10.2759 25.936 9.92647C25.5829 9.57703 25.1148 9.36788 24.619 9.33798M21.203 14.875C21.8236 14.875 22.3265 14.3523 22.3265 13.7083C22.3265 13.0643 21.8236 12.5416 21.203 12.5416C20.5811 12.5416 20.0783 13.0643 20.0783 13.7083C20.0783 14.3523 20.5811 14.875 21.203 14.875Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.4043 19.8333C24.4446 19.8317 24.4848 19.8396 24.5215 19.8564C24.5582 19.8732 24.5904 19.8985 24.6155 19.9301C24.6407 19.9617 24.658 19.9987 24.6661 20.0383C24.6742 20.0778 24.6728 20.1187 24.6621 20.1577C24.4288 20.9883 24.0566 21.6977 23.4605 22.2927C22.5866 23.1677 21.4795 23.5538 20.1121 23.7382C18.7821 23.9167 17.0846 23.9167 14.9403 23.9167H12.4763C10.332 23.9167 8.63331 23.9167 7.30448 23.7382C5.93715 23.5538 4.82998 23.1665 3.95615 22.2938C3.08348 21.42 2.69615 20.3128 2.51181 18.9455C2.33331 17.6155 2.33331 15.918 2.33331 13.7737V13.643C2.33331 11.4987 2.33331 9.8 2.51181 8.47C2.69615 7.10267 3.08348 5.9955 3.95615 5.12167C4.82998 4.249 5.93715 3.86167 7.30448 3.67733C8.63448 3.5 10.332 3.5 12.4763 3.5H14.9403C17.0846 3.5 18.7833 3.5 20.1121 3.6785C21.4795 3.86283 22.5866 4.25017 23.4605 5.12283C24.0566 5.72017 24.4288 6.42833 24.6621 7.259C24.6728 7.29793 24.6742 7.33883 24.6661 7.37837C24.658 7.41792 24.6407 7.455 24.6155 7.48659C24.5904 7.51819 24.5582 7.54342 24.5215 7.56023C24.4848 7.57704 24.4446 7.58495 24.4043 7.58333H21.4596C18.1498 7.58333 15.2331 10.1967 15.2331 13.7083C15.2331 17.22 18.1498 19.8333 21.4596 19.8333H24.4043ZM6.70831 8.16667C6.47625 8.16667 6.25369 8.25885 6.08959 8.42295C5.9255 8.58704 5.83331 8.8096 5.83331 9.04167C5.83331 9.27373 5.9255 9.49629 6.08959 9.66038C6.25369 9.82448 6.47625 9.91667 6.70831 9.91667H11.375C11.607 9.91667 11.8296 9.82448 11.9937 9.66038C12.1578 9.49629 12.25 9.27373 12.25 9.04167C12.25 8.8096 12.1578 8.58704 11.9937 8.42295C11.8296 8.25885 11.607 8.16667 11.375 8.16667H6.70831Z"
            fill="white"
        />
    </svg>
);
