import { FC } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

import { Button } from "./button.component";
import { ManageAccountButtonsProps } from "types";
import { manageAccountButtons } from "constants/index";
import { ButtonType, SectionId } from "enums/index";
import { HeaderArrow } from "assets/icons/header-arrow.icon";
import { scrollIntoElementById } from "utils/scroll-into-element-by-id";
import { HOME_PATH } from "router/constants";
import { useNavigate } from "react-router-dom";

interface Props {
    listClassName?: string;
    itemClassName?: string;
    buttonClassName?: string;
    onButtonClick?: () => void;
    hasIcon?: boolean;
}

export const ManageAccountList: FC<Props> = ({
    listClassName,
    itemClassName,
    buttonClassName,
    onButtonClick,
    hasIcon = false
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const renderManageAccountButton = ({ name, path, type }: ManageAccountButtonsProps) => {
        const handleClick = () => {
            navigate(path === SectionId.HOME_PAGE ? HOME_PATH : `/#${path}`);
            onButtonClick?.();
            scrollIntoElementById(path);
        };

        return (
            <li aria-label={name} key={name} className={itemClassName}>
                {hasIcon ? (
                    <Button
                        buttonType={ButtonType.GREEN}
                        className="pl-4 pr-2 mr-4 max-w-fit font-bold text-athens-gray mb-20 lg:mb-8 lg:max-w-none lg:w-full lg:flex lg:justify-center"
                        onClick={handleClick}
                        icon={
                            <div className="p-2 bg-athens-gray rounded ml-3">
                                <HeaderArrow />
                            </div>
                        }>
                        {t(name)}
                    </Button>
                ) : (
                    <Button
                        buttonType={type}
                        className={twMerge("px-5", buttonClassName)}
                        onClick={handleClick}>
                        {t(name)}
                    </Button>
                )}
            </li>
        );
    };

    return (
        <ul
            aria-label="Manage account buttons"
            className={twMerge("list-none lg:mr-3", listClassName)}>
            {manageAccountButtons.map(renderManageAccountButton)}
        </ul>
    );
};
