import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Zoom, Slide, Fade } from "react-awesome-reveal";

import { MenuSlidingPanel } from "./common/menu-sliding-panel.component";
import { NavigationList } from "./common/navigation-list.component";
import { scrollIntoElementById } from "utils/scroll-into-element-by-id";
import { HOME_PATH } from "router/constants";
import { SectionId } from "enums";
import { ManageAccountList } from "./common/manage-account-list.component";
import { Logo } from "./common/logo.component";

export const Navbar: FC = () => {
    const location = useLocation();

    const isHomePage = location.pathname === HOME_PATH;

    const [isSlidingPanelVisible, setIsSlidingPanelVisible] = useState(false);
    const [activeLinkId, setActiveLinkId] = useState<SectionId | null>(
        isHomePage
            ? location.hash
                ? (location.hash.replace("#", "") as SectionId)
                : SectionId.HOME_PAGE
            : null
    );

    useEffect(() => {
        if (location.hash) {
            const hashId = location.hash.replace("#", "") as SectionId;

            const searchedId = hashId || activeLinkId;

            scrollIntoElementById(searchedId, "auto");
            location.hash = "";
        }
    }, [activeLinkId, location]);

    useEffect(() => {
        if (!isHomePage) {
            setActiveLinkId(null);
        }
    }, [isHomePage]);

    const openSlidingPanel = () => setIsSlidingPanelVisible(true);

    const closeSlidingPanel = () => setIsSlidingPanelVisible(false);

    const handleLinkClick = (id: SectionId) => {
        setActiveLinkId(id);
        scrollIntoElementById(id);
        isSlidingPanelVisible && closeSlidingPanel();
    };

    const nullActiveLink = () => {
        setActiveLinkId(null);
    };

    return (
        <>
            <nav
                id={SectionId.HOME_PAGE}
                aria-label="Navigation"
                className="h-20 w-full absolute top-0 left-0 z-10 flex justify-center">
                <section className="flex-row max-w-360 flex items-center w-full px-4">
                    <Zoom triggerOnce className="flex-1">
                        <Logo />
                    </Zoom>

                    <Zoom triggerOnce className="flex h-full">
                        <NavigationList
                            activeLinkId={activeLinkId}
                            onLinkClick={handleLinkClick}
                            listClassName="h-full flex justify-center items-center gap-x-7 px-7 lg:hidden"
                        />
                    </Zoom>
                    <Zoom triggerOnce className="flex-2 flex justify-end">
                        <ManageAccountList
                            listClassName="flex gap-x-5 sm:hidden sm:mr-5"
                            onButtonClick={nullActiveLink}
                        />
                    </Zoom>
                    <Slide triggerOnce direction="right">
                        <Fade triggerOnce delay={200}>
                            <FontAwesomeIcon
                                icon={faBars}
                                className="hidden lg:block relative translate-y-0 right-0 text-2xl hover:text-primary cursor-pointer"
                                onClick={openSlidingPanel}
                            />
                        </Fade>
                    </Slide>
                </section>
            </nav>
            <MenuSlidingPanel
                activeLinkId={activeLinkId}
                onLinkClick={handleLinkClick}
                isPanelOpen={isSlidingPanelVisible}
                onClose={closeSlidingPanel}
            />
        </>
    );
};
