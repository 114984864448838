import { FC } from "react";
import { Slide } from "react-awesome-reveal";
import { WhyItem } from "./common/why-we-item.component";
import { Trans } from "react-i18next";
import { PocketIcon } from "assets/icons/pocket.icon";
import { ClockIcon } from "assets/icons/clock.icon";
import { SupportIcon } from "assets/icons/support.icon";
import { SectionId } from "enums/index";

interface Item {
    header: string;
    text: string;
    icon: JSX.Element;
}

const whyFastArray: Item[] = [
    {
        header: "whyWe.result",
        text: "whyWe.quickly",
        icon: <ClockIcon />
    },
    {
        header: "whyWe.support",
        text: "whyWe.provide",
        icon: <PocketIcon />
    },
    {
        header: "whyWe.money",
        text: "whyWe.because",
        icon: <SupportIcon />
    }
];

export const WhyWe: FC = () => {
    const renderList = (item: Item) => (
        <WhyItem header={item.header} text={item.text} icon={item.icon} key={item.header} />
    );

    return (
        <section className="w-full bg-gray mt-2 flex justify-center" id={SectionId.WHYWE}>
            <Slide triggerOnce direction="left">
                <div className="max-w-360 w-screen flex justify-between py-20 px-8 lg:block lg:py-14">
                    <div className="lg:mb-14">
                        <h1 className="text-ebony-clay text-5xl font-semibold min-w-1/3 mb-2">
                            <Trans
                                i18nKey={"whyWe.header"}
                                components={{ color: <span className="text-primary" /> }}
                            />
                        </h1>
                        <p className="text-base text-shuttle-gray">
                            <Trans i18nKey={"whyWe.business"} />
                        </p>
                    </div>
                    <ul className="">{whyFastArray.map(renderList)}</ul>
                </div>
            </Slide>
        </section>
    );
};
