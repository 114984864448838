import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Button } from "./common/button.component";
import { ButtonType, SectionId } from "enums/index";
import { Zoom } from "react-awesome-reveal";
import { HeaderIcon } from "assets/icons/header.icon";
import { HeaderArrow } from "assets/icons/header-arrow.icon";
import { HeaderTriangle } from "assets/icons/heeader-triangle.icon";
import { scrollIntoElementById } from "utils/scroll-into-element-by-id";

export const Header: FC = () => {
    const { t } = useTranslation();
    const handleClick = (path: SectionId) => () => {
        scrollIntoElementById(path);
    };

    return (
        <header className="overflow-hidden flex flex-col items-center">
            <Zoom triggerOnce>
                <section className="w-screen min-h-190 px-4 pt-64 max-w-360 flex justify-between lg:pt-32 lg:block lg:bg-none lg:min-h-full">
                    <div>
                        <h1 className="text-ebony-clay text-16 font-semibold mb-2 lg:text-10">
                            <Trans
                                i18nKey="header.impressions"
                                components={{ color: <span className="text-primary" /> }}
                            />
                        </h1>
                        <p className="text-shuttle-gray text-xl font-normal mb-10">
                            <Trans i18nKey="header.special" />
                        </p>
                        <div className="flex lg:flex-col">
                            <Button
                                buttonType={ButtonType.GREEN}
                                className="pl-4 pr-2 mr-4 max-w-fit font-bold text-athens-gray mb-20 lg:mb-8 lg:max-w-none lg:w-full lg:flex lg:justify-center"
                                onClick={handleClick(SectionId.PRODUCTS)}
                                icon={
                                    <div className="p-2 bg-athens-gray rounded ml-3">
                                        <HeaderArrow />
                                    </div>
                                }>
                                {t("navigation.getCard")}
                            </Button>
                            <Button
                                buttonType={ButtonType.CLEAR}
                                className="max-w-fit lg:max-w-none lg:w-full lg:flex lg:justify-center"
                                onClick={handleClick(SectionId.HOW)}
                                icon={<HeaderTriangle className="ml-2" />}>
                                {t("navigation.how")}
                            </Button>
                        </div>
                    </div>
                    <HeaderIcon className="absolute right-0 top-0 -z-10 lg:relative lg:w-full" />
                </section>
            </Zoom>
        </header>
    );
};
