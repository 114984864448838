import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    header: string;
    text: string;
    icon: JSX.Element;
}

export const WhyItem: FC<Props> = ({ header, text, icon }) => {
    const { t } = useTranslation();

    return (
        <li className="flex py-6 border-l-4 border-primary px-8 bg-athens-gray rounded-lg mb-4 transition-all last:mb-0 hover:scale-105 hover:shadow-lg">
            <div className="px-3 h-13 mr-6 rounded flex justify-center items-center bg-primary">
                {icon}
            </div>
            <section>
                <h1 className="text-ebony-clay font-bold text-xl mb-2 leading-7">{t(header)}</h1>
                <p className="text-sm text-shuttle-gray font-normal">{t(text)}</p>
            </section>
        </li>
    );
};
