import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { supportLink } from "constants/index";

interface Props {
    className: string;
}

export const Contact: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <>
            <p className={twMerge(className, "mt-3 mb-4")}>{t("privacyPolicy.change")}</p>
            <a href={supportLink} className={twMerge(className, "text-primary underline")}>
                {supportLink}
            </a>
        </>
    );
};
