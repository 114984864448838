export enum SectionId {
    WHYWE = "why-we",
    HOME_PAGE = "home",
    FAQ = "faq",
    PRODUCTS = "products",
    HOW = "how-it-works",
    TERMS = "terms-of-service",
    PRIVACY_POLICY = "privacy-policy"
}
export enum LinkModule {
    FAQ = "faq",
    CHECKOUT = "checkout",
    PUBLIC = "public"
}

export enum ButtonType {
    GREEN = "green",
    BLACK = "black",
    CLEAR = "clear"
}

export enum PackageId {
    FIRST = "?packId=1",
    SECOND = "?packId=2",
    THIRD = "?packId=3"
}
