import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { ButtonType } from "enums";

interface IProps extends PropsWithChildren {
    className?: string;
    type?: "button" | "submit";
    onClick?: () => void;
    arialLabel?: string;
    buttonType?: string;
    icon?: JSX.Element;
}

export const Button = ({
    className,
    type = "button",
    children,
    onClick,
    arialLabel,
    buttonType = ButtonType.BLACK,
    icon
}: IProps) => (
    <button
        aria-label={arialLabel}
        type={type}
        className={twMerge(
            "w-full h-11 px-4 rounded text-sm text-nowrap font-bold leading-5 cursor-pointer duration-300 flex items-center",
            buttonType === ButtonType.BLACK && "bg-ebony-clay hover:bg-mirage text-athens-gray",
            buttonType === ButtonType.GREEN && "text-athens-gray bg-primary hover:bg-salem",
            buttonType === ButtonType.CLEAR &&
                "text-primary border border-transparent hover:border-salem hover:text-salem",
            className
        )}
        onClick={onClick}>
        {children}
        {icon}
    </button>
);
