import { useTranslation, Trans } from "react-i18next";
import { UseFormRegister, FieldErrors } from "react-hook-form";

interface IFormValues {
    businessName: string;
    address: string;
    phone: string;
    email: string;
}

type InputProps = {
    label: string;
    register: UseFormRegister<IFormValues>;
    required: boolean;
    className?: string;
    errors: FieldErrors<IFormValues>;
};

export const Input = ({ register, required, label, className, errors }: InputProps) => {
    const { t } = useTranslation();
    const defaultInputStyles =
        "px-4 py-2 rounded-md border border-gray text-sm text-ebony-clay pt-7 w-full focus:outline-primary focus:outline-1";

    const setError = (label: string) => {
        switch (label) {
            case "businessName":
                return {
                    required,
                    minLength: { value: 2, message: t("details.min2") }
                };
            case "address":
                return {
                    required,
                    minLength: { value: 5, message: t("details.min5") }
                };
            case "phone":
                return {
                    required,
                    pattern: {
                        value: /^(\+[1-9]{1}[0-9]{3,14})?([0-9]{9,14})$/,
                        message: t("details.validNumber")
                    }
                };
            case "email":
                return {
                    required,
                    pattern: {
                        value: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: t("details.validEmail")
                    }
                };
        }
    };

    return (
        <>
            <label className="relative w-full mb-2 last:mb-0" key={label}>
                {errors[label as keyof IFormValues] && (
                    <span className="font-outfit text-xs absolute right-4 top-2 text-red-500">
                        {errors[label as keyof IFormValues]?.message}
                    </span>
                )}
                <p className="absolute top-2 left-4 text-xs">
                    <Trans
                        i18nKey={`details.${label}`}
                        components={{
                            color: <span className="text-primary" />
                        }}
                    />
                </p>
                {label === "phone" ? (
                    <input
                        autoComplete="off"
                        type="number"
                        className={defaultInputStyles}
                        {...register(t(label), setError(label))}
                    />
                ) : (
                    <input
                        autoComplete="off"
                        type="text"
                        className={defaultInputStyles}
                        {...register(t(label), setError(label))}
                    />
                )}
            </label>
        </>
    );
};
