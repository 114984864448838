import { FC } from "react";
import { Header } from "components/header.component";
import { WhyWe } from "components/why-we.component";
import { HowItWorks } from "components/how-it-works.component";
import { CardType } from "components/card-type.component";
import { FAQ } from "components/faq.component";

export const Home: FC = () => {
    return (
        <>
            <Header />
            <main className="px-4 flex flex-col items-center justify-center">
                <WhyWe />
                <HowItWorks />
                <CardType />
                <FAQ />
            </main>
        </>
    );
};
