import { FC, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Zoom } from "react-awesome-reveal";
import { SectionId } from "enums/index";
import { Collapse } from "./common/collapse.component";

interface FaqDataContent {
    id: string;
    title: string;
    content: JSX.Element;
}

export const FAQ: FC = () => {
    const { t } = useTranslation();
    const textStyle = "text-sm text-shuttle-gray";

    const renderQuestion = ({ id, title, content }: FaqDataContent) => (
        <li key={id} className="border-b border-black border-opacity-15 px-8 py-6 lg:px-4">
            <Collapse title={title} content={content} />
        </li>
    );

    const faqData: FaqDataContent[] = useMemo(
        () => [
            {
                id: "1",
                title: "faq.purchasing",
                content: <p className={textStyle}>{t("faq.package")}</p>
            },
            {
                id: "2",
                title: "faq.business",
                content: <p className={textStyle}>{t("faq.traditionally")}</p>
            },
            {
                id: "3",
                title: "faq.thick",
                content: <p className={textStyle}>{t("faq.usually")}</p>
            }
        ],
        [t]
    );

    return (
        <section
            aria-label={t("choosePlan.header")}
            id={SectionId.FAQ}
            className="w-full py-20 px-9 mt-2 flex flex-col items-center justify-center max-w-360 lg:px-4">
            <Zoom triggerOnce>
                <h1 className="text-ebony-clay text-5xl font-semibold mb-8 lg:text-3xl lg:text-center">
                    <Trans
                        i18nKey="faq.faq"
                        components={{ color: <span className="text-primary" /> }}
                    />
                </h1>
                <ul className="w-full">{faqData.map(renderQuestion)}</ul>
            </Zoom>
        </section>
    );
};
