import { FC, SVGProps } from "react";

export const XMarkIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width={24} {...props}>
        <path
            d="M16.9497 8.46251L13.4142 11.998L16.9497 15.5336C17.1373 15.7211 17.2426 15.9755 17.2426 16.2407C17.2426 16.5059 17.1373 16.7603 16.9497 16.9478C16.7622 17.1353 16.5079 17.2407 16.2426 17.2407C15.9774 17.2407 15.7231 17.1353 15.5355 16.9478L12 13.4123L8.46446 16.9478C8.27693 17.1353 8.02257 17.2407 7.75736 17.2407C7.49214 17.2407 7.23779 17.1353 7.05025 16.9478C6.86271 16.7603 6.75736 16.5059 6.75736 16.2407C6.75736 15.9755 6.86271 15.7211 7.05025 15.5336L10.5858 11.998L7.05025 8.46251C6.86271 8.27498 6.75736 8.02062 6.75736 7.75541C6.75736 7.49019 6.86271 7.23584 7.05025 7.0483C7.23779 6.86076 7.49214 6.75541 7.75736 6.75541C8.02257 6.75541 8.27693 6.86076 8.46446 7.0483L12 10.5838L15.5355 7.0483C15.7231 6.86076 15.9774 6.75541 16.2426 6.75541C16.5079 6.75541 16.7622 6.86076 16.9497 7.0483C17.1373 7.23584 17.2426 7.49019 17.2426 7.75541C17.2426 8.02062 17.1373 8.27498 16.9497 8.46251Z"
            fill="#31D97C"
        />
    </svg>
);
