import { FC } from "react";
import { Trans } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { supportLink } from "constants/index";

interface Props {
    className: string;
}

export const Contact: FC<Props> = ({ className }) => {
    return (
        <>
            <p className={twMerge(className, "mt-3 mb-4")}>
                <Trans i18nKey="terms.resolve" />
            </p>
            <p className={className}>
                <Trans
                    i18nKey="terms.email"
                    components={{
                        "support-link": (
                            <a className="text-primary underline" href={"mailto:" + supportLink}>
                                {supportLink}
                            </a>
                        )
                    }}
                />
            </p>
        </>
    );
};
