import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { originLink } from "constants/index";

interface Props {
    className: string;
}

export const Agreement: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <>
            <p className={twMerge(className, "mt-3 mb-4")}>
                <Trans
                    i18nKey="terms.constitute"
                    components={{
                        "color-link": (
                            <a
                                className="text-primary underline"
                                href={originLink}
                                rel="noopener noreferrer">
                                {originLink}
                            </a>
                        )
                    }}
                />
            </p>
            <p className={twMerge(className, "mb-4")}>{t("terms.conditions")}</p>
            <p className={twMerge(className, "mb-4")}>{t("terms.information")}</p>
            <p className={twMerge(className, "mb-4")}>{t("terms.tailored")}</p>
            <p className={className}>{t("terms.minors")}</p>
        </>
    );
};
