import { FC, useEffect } from "react";
import { Wrapper } from "components/common/wrapper.component";
import { Trans, useTranslation } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import { twMerge } from "tailwind-merge";
import { Information } from "components/privacy/information.component";
import { Collect } from "components/privacy/collect.component";
import { Contact } from "components/privacy/contact.component";
import { originLink } from "constants/index";
import { scrollToTop } from "utils/to-top";

interface LiItem {
    header: string;
    content: JSX.Element;
}

export const Privacy: FC = () => {
    const { t } = useTranslation();
    const defaultStyles = "text-sm text-shuttle-gray font-normal leading-6";

    const privacyItems = [
        {
            header: "information",
            content: <Information className={defaultStyles} />
        },
        {
            header: "collect",
            content: <Collect className={defaultStyles} />
        },
        {
            header: "contact",
            content: <Contact className={defaultStyles} />
        }
    ];
    const renderLi = ({ header, content }: LiItem) => (
        <Slide triggerOnce key={header}>
            <li className="font-bold text-xl text-ebony-clay mb-4">
                {t(`privacyPolicy.${header}`)}
                {content}
            </li>
        </Slide>
    );

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <Wrapper header="privacyPolicy.header" arrow={true}>
            <p className={twMerge(defaultStyles, "mb-4 w-9/12 lg:w-full")}>
                <Trans
                    i18nKey="privacyPolicy.explains"
                    components={{
                        "origin-link": <span>{originLink}</span>
                    }}
                />
            </p>
            <ul className="list-none w-9/12 lg:w-full">{privacyItems.map(renderLi)}</ul>
        </Wrapper>
    );
};
