import { ButtonType, SectionId } from "enums";
import {
    NavigationLinkProps,
    ManageAccountButtonsProps,
    PrivacyProps,
    ItemDesign,
    ItemCardType,
    ItemQuantity
} from "types";
import { HowItWorksItem } from "types";
import { PRIVACY, TERMS, REFUND, SHIPPING } from "router/constants";

import Design1 from "assets/images/design1.png";
import Design2 from "assets/images/design2.png";
import Design3 from "assets/images/design3.png";
import Design4 from "assets/images/design4.png";
import Design5 from "assets/images/design5.png";

import Card1 from "assets/images/card1.png";
import Card2 from "assets/images/card2.png";
import Card3 from "assets/images/card3.png";

export const navigationLinkContent: NavigationLinkProps[] = [
    {
        name: "navigation.how",
        id: SectionId.HOW
    },
    {
        name: "navigation.products",
        id: SectionId.PRODUCTS
    },
    {
        name: "navigation.why",
        id: SectionId.WHYWE
    },
    {
        name: "navigation.faq",
        id: SectionId.FAQ
    }
];

export const privacyContent: PrivacyProps[] = [
    {
        name: "privacy.privacyPolicy",
        id: PRIVACY
    },
    {
        name: "privacy.terms",
        id: TERMS
    },
    {
        name: "privacy.refund",
        id: REFUND
    },
    {
        name: "privacy.shipping",
        id: SHIPPING
    }
];

export const manageAccountButtons: ManageAccountButtonsProps[] = [
    {
        name: "navigation.getCard",
        path: SectionId.PRODUCTS,
        type: ButtonType.BLACK
    }
];

export const howItWorksArray: HowItWorksItem[] = [
    {
        step: 1,
        header: "howItWorks.type",
        text: "howItWorks.choose"
    },
    {
        step: 2,
        header: "howItWorks.payment",
        text: "howItWorks.card"
    },
    {
        step: 3,
        header: "howItWorks.contact",
        text: "howItWorks.purchasing"
    }
];

export const supportLink = `support@${window.location.hostname}`;

export const originLink = window.location.origin;

export const designArray: ItemDesign[] = [
    {
        id: 1,
        image: Design1
    },
    {
        id: 2,
        image: Design2
    },
    {
        id: 3,
        image: Design3
    },
    {
        id: 4,
        image: Design4
    },
    {
        id: 5,
        image: Design5
    }
];

export const cardTypeArray: ItemCardType[] = [
    {
        header: "standard",
        image: Card1,
        size: '3.5" x 2.0"'
    },
    {
        header: "business",
        image: Card2,
        size: '2.56" x 2.56"'
    },
    {
        header: "specialBusiness",
        image: Card3,
        size: '3.3" x 2.16"'
    }
];

export const quantityArray: ItemQuantity[] = [
    {
        id: 100,
        price: 24.99
    },
    {
        id: 250,
        price: 51.99
    },
    {
        id: 400,
        price: 89.99
    },
    {
        id: 700,
        price: 159.99
    }
];
