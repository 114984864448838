import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { ButtonType, SectionId } from "enums/index";
import { Zoom } from "react-awesome-reveal";
import Card1Image from "assets/images/card1.png";
import Card2Image from "assets/images/card2.png";
import Card3Image from "assets/images/card3.png";
import { Button } from "./common/button.component";
import { useNavigate } from "react-router-dom";
import { DETAILS } from "router/constants";

interface CardTypeItem {
    image: string;
    header: string;
    cardSize: string;
    button: string;
    path: string;
}

export const cardTypeArray: CardTypeItem[] = [
    {
        image: Card1Image,
        header: "cardType.standard",
        cardSize: '3.5" x 2.0"',
        button: "cardType.card",
        path: "/?packId=1"
    },
    {
        image: Card2Image,
        header: "cardType.box",
        cardSize: '2.56" x 2.56"',
        button: "cardType.pick",
        path: "/?packId=2"
    },
    {
        image: Card3Image,
        header: "cardType.special",
        cardSize: '3.3" x 2.16"',
        button: "cardType.specialSize",
        path: "/?packId=3"
    }
];

export const CardType: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = (path: string) => () => {
        navigate(DETAILS + path);
    };
    const renderList = (item: CardTypeItem) => (
        <li
            className="bg-white rounded-lg transition-all hover:scale-105 hover:shadow-lg lg:mb-4 lg:last:mb-0"
            key={item.cardSize}>
            <img src={item.image} alt={t(item.header)} />
            <div className="p-6">
                <h1 className="text-xl font-bold mb-2 text-ebony-clay ">{t(item.header)}</h1>
                <p className="text-sm text-shuttle-gray mb-5">
                    {t("cardType.size")} <span>{t(item.cardSize)}</span>
                </p>
                <Button
                    buttonType={ButtonType.CLEAR}
                    className="border border-primary max-w-fit lg:max-w-none lg:w-full lg:flex lg:justify-center"
                    onClick={handleClick(item.path)}>
                    {t(item.button)}
                </Button>
            </div>
        </li>
    );

    return (
        <section className="w-full mt-2 flex justify-center bg-gray" id={SectionId.PRODUCTS}>
            <Zoom triggerOnce>
                <div className="max-w-360 w-screen py-20 px-8 text-center lg:block lg:py-14">
                    <h1 className="text-ebony-clay text-5xl font-semibold min-w-1/3 mb-2 lg:text-3xl">
                        <Trans
                            i18nKey={"cardType.choose"}
                            components={{ color: <span className="text-primary" /> }}
                        />
                    </h1>
                    <p className="text-base text-shuttle-gray mb-8">
                        <Trans i18nKey={"cardType.business"} />
                    </p>
                    <ul className="flex justify-between text-left lg:flex-col">
                        {cardTypeArray.map(renderList)}
                    </ul>
                </div>
            </Zoom>
        </section>
    );
};
