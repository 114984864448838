import { FC, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import { ButtonToTop } from "./button-to-top.component";
import { Trans, useTranslation } from "react-i18next";
import { LeftArrowIcon } from "assets/icons/left-arrow.icon";
import { Link } from "react-router-dom";
import { HOME_PATH } from "router/constants";

interface Props extends PropsWithChildren {
    className?: string;
    classNameHeader?: string;
    header: string;
    arrow?: boolean;
}

export const Wrapper: FC<Props> = ({
    className,
    classNameHeader,
    header,
    children,
    arrow = false
}) => {
    const { t } = useTranslation();
    return (
        <main
            className={twMerge(
                "w-full flex flex-col justify-start bg-header bg-no-repeat bg-contain items-center pt-28 lg:px-4 lg:pb-0 lg:pt-20",
                className
            )}>
            <div className="max-w-360 px-4 w-full">
                <nav className="flex items-center list-none mb-3 text-opacity-40">
                    <li className="transition-all flex items-center hover:opacity-50">
                        <LeftArrowIcon className="mr-3 w-3" />
                        <Link to={HOME_PATH} className="text-sm ">
                            {t("navigation.goBack")}
                        </Link>
                    </li>
                </nav>
                <h1
                    className={twMerge(
                        "text-ebony-clay text-5xl font-semibold mb-8 lg:text-3xl",
                        classNameHeader
                    )}>
                    <Trans
                        i18nKey={header}
                        components={{
                            color: <span className="text-primary" />
                        }}
                    />
                </h1>
                <section aria-label={header} className="relative w-full">
                    {children}
                </section>
                {arrow && <ButtonToTop />}
            </div>
        </main>
    );
};
