import { FC, useEffect } from "react";
import { Wrapper } from "components/common/wrapper.component";
import { Trans, useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { supportLink } from "constants/index";
import RefundImage1 from "assets/images/refund-image1.png";
import RefundImage2 from "assets/images/refund-image2.png";
import { scrollToTop } from "utils/to-top";

export const Refund: FC = () => {
    const { t } = useTranslation();
    const defaultStyles = "text-sm text-shuttle-gray font-normal leading-6";

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <Wrapper header="refund.header" arrow={true}>
            <section className={twMerge(defaultStyles, "mt-3 w-9/12 lg:w-full")}>
                <p className={twMerge(defaultStyles, "mb-4")}>{t("refund.purchase")}</p>
                <p className={twMerge(defaultStyles, "mb-4")}>{t("refund.completely")}</p>
                <section className="flex justify-between">
                    <div className="w-190/4">
                        <div className="bg-gray flex justify-center items-center h-48 mb-2">
                            <img src={RefundImage1} alt="Backpack" />
                        </div>
                        <h2 className="text-xl font-bold mb-3 text-ebony-clay">
                            {t("refund.refunds")}
                        </h2>
                        <p className={twMerge(defaultStyles, "mb-4")}>
                            <Trans
                                i18nKey="refund.within"
                                components={{
                                    "support-link": (
                                        <a
                                            className="text-primary underline"
                                            href={"mailto:" + supportLink}>
                                            {supportLink}
                                        </a>
                                    )
                                }}
                            />
                        </p>
                        <p className={defaultStyles}>{t("refund.request")}</p>
                    </div>
                    <div className="w-190/4">
                        <div className="bg-gray flex justify-center items-center h-48 mb-2">
                            <img src={RefundImage2} alt="Box" />
                        </div>
                        <h2 className="text-xl font-bold mb-3 text-ebony-clay">
                            {t("refund.returns")}
                        </h2>
                        <p className={twMerge(defaultStyles, "mb-4")}>{t("refund.pleased")}</p>
                        <p className={defaultStyles}>{t("refund.approved")}</p>
                    </div>
                </section>
                <h1 className={twMerge(defaultStyles, "text-ebony-clay font-bold text-xl mb-3")}>
                    {t("refund.exceptions")}
                </h1>
                <p className={twMerge(defaultStyles, "mb-4")}>
                    <Trans
                        i18nKey="refund.defective"
                        components={{
                            "support-link": (
                                <a
                                    className="text-primary underline"
                                    href={"mailto:" + supportLink}>
                                    {supportLink}
                                </a>
                            )
                        }}
                    />
                </p>
                <p className={twMerge(defaultStyles, "text-ebony-clay font-semibold")}>
                    <Trans
                        i18nKey="refund.product"
                        components={{
                            "support-link": <a href={"mailto:" + supportLink}>{supportLink}</a>
                        }}
                    />
                </p>
            </section>
        </Wrapper>
    );
};
