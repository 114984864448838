import { FC, SVGProps } from "react";

export const LeftArrowIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" width={16} {...props}>
        <path
            d="M15.5 6.99998C15.5 7.16574 15.4342 7.32471 15.3169 7.44192C15.1997 7.55913 15.0408 7.62498 14.875 7.62498H2.6336L7.19219 12.1828C7.25026 12.2409 7.29632 12.3098 7.32775 12.3857C7.35918 12.4615 7.37535 12.5429 7.37535 12.625C7.37535 12.7071 7.35918 12.7884 7.32775 12.8643C7.29632 12.9402 7.25026 13.0091 7.19219 13.0672C7.13412 13.1252 7.06518 13.1713 6.98931 13.2027C6.91344 13.2342 6.83213 13.2503 6.75 13.2503C6.66788 13.2503 6.58656 13.2342 6.51069 13.2027C6.43482 13.1713 6.36588 13.1252 6.30782 13.0672L0.682816 7.44217C0.624706 7.38412 0.578606 7.31519 0.547154 7.23932C0.515701 7.16344 0.499512 7.08212 0.499512 6.99998C0.499512 6.91785 0.515701 6.83652 0.547154 6.76064C0.578606 6.68477 0.624706 6.61584 0.682816 6.55779L6.30782 0.932794C6.42509 0.815518 6.58415 0.749634 6.75 0.749634C6.91586 0.749634 7.07492 0.815518 7.19219 0.932794C7.30947 1.05007 7.37535 1.20913 7.37535 1.37498C7.37535 1.54083 7.30947 1.69989 7.19219 1.81717L2.6336 6.37498H14.875C15.0408 6.37498 15.1997 6.44083 15.3169 6.55804C15.4342 6.67525 15.5 6.83422 15.5 6.99998Z"
            fill="#595E6A"
        />
    </svg>
);
