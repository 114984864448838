import { FC, SVGProps } from "react";

export const OrderArrow: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" width={18} {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.99996 16.8333C13.6023 16.8333 17.3333 13.1023 17.3333 8.49996C17.3333 3.89759 13.6023 0.166626 8.99996 0.166626C4.39759 0.166626 0.666626 3.89759 0.666626 8.49996C0.666626 13.1023 4.39759 16.8333 8.99996 16.8333ZM13.7084 6.12496L7.83337 12L4.29171 8.45829L5.45837 7.29163L7.83337 9.66663L12.5417 4.95829L13.7084 6.12496Z"
            fill="#31D97C"
        />
    </svg>
);
