import { FC } from "react";
import { Trans } from "react-i18next";
import { PRIVACY } from "router/constants";
import { twMerge } from "tailwind-merge";
import { originLink } from "constants/index";

interface Props {
    className: string;
}

export const PrivacyPolicy: FC<Props> = ({ className }) => {
    const privacyLink = originLink + PRIVACY;
    return (
        <p className={twMerge(className, "mt-3 mb-4")}>
            {
                <Trans
                    i18nKey="terms.care"
                    components={{
                        "color-link": (
                            <a
                                className="text-primary underline"
                                href={privacyLink}
                                rel="noopener noreferrer">
                                {privacyLink}
                            </a>
                        )
                    }}
                />
            }
        </p>
    );
};
