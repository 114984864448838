import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    className: string;
}

export const Collect: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <>
            <p className={twMerge(className, "mt-3 mb-4")}>{t("privacyPolicy.website")}</p>
            <p className={twMerge(className, "mb-4")}>{t("privacyPolicy.browsers")}</p>
            <p className={twMerge(className, "mb-4")}>{t("privacyPolicy.store")}</p>
            <p className={twMerge(className, "mb-4")}>{t("privacyPolicy.receive")}</p>
            <p className={className}>{t("privacyPolicy.advertising")}</p>
        </>
    );
};
