import { FC, useEffect, useState } from "react";
import { Wrapper } from "components/common/wrapper.component";
import { Trans, useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useForm, SubmitHandler } from "react-hook-form";

import { OrderArrow } from "assets/icons/order-arrow.icon";
import { OrderEmpty } from "assets/icons/order-empty-arrow.icon";
import { twMerge } from "tailwind-merge";
import { Zoom } from "react-awesome-reveal";
import { Input } from "components/common/input.comoponent";
import { ButtonType, PackageId } from "enums/index";
import { Button } from "components/common/button.component";
import { Link } from "react-router-dom";
import { Inputs, ItemCardType, ItemDesign, ItemQuantity, OrderTypeItem } from "types/index";

import { TERMS } from "router/constants";

import { designArray, cardTypeArray, quantityArray } from "constants/index";
import { scrollToTop } from "utils/to-top";

export const OrderDetails: FC = () => {
    const { t } = useTranslation();

    const [activeDesign, setActiveDesign] = useState(1);
    const [activeQuantity, setActiveQuantity] = useState(89.99);
    const [activeSize, setActiveSize] = useState('3.5" x 2.0"');

    const inputsArray = ["businessName", "address", "phone", "email"];
    const defaultUlStyle = "w-full flex justify-between flex-wrap lg:flex-col pl-10 lg:pl-0";

    const handleChangeDesign = (id: number) => () => {
        setActiveDesign(id);
    };
    const handleChangeQuantity = (id: number) => () => {
        setActiveQuantity(id);
    };
    const handleActiveSize = (id: string) => () => {
        setActiveSize(id);
    };

    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        reset
    } = useForm<Inputs>({
        mode: "all"
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        // const res = {
        //     design: activeDesign,
        //     price: activeQuantity,
        //     size: activeSize,
        //     ...data
        // };
        try {
            toast.error(t("messages.error"));
        } catch (error: any) {
            toast.error(t("messages.sent"));
            // toast.error(error?.response?.data?.message || t("messages.notSent"));
        }
        reset();
    };

    const renderItem = ({ id, image }: ItemDesign) => (
        <Zoom triggerOnce key={id}>
            <li
                className={twMerge(
                    "w-56 rounded-xl border-2 border-transparent shadow-2xl transition-all cursor-pointer mb-10 hover:scale-105 lg:w-full lg:mb-4 lg:last:mb-0 lg:hover:scale-100",
                    activeDesign === id && "border-primary"
                )}
                onClick={handleChangeDesign(id)}>
                <section className="p-4 flex justify-items-center">
                    {activeDesign === id ? <OrderArrow /> : <OrderEmpty />}
                    <h2 className="text-ebony-clay text-base ml-2 font-semibold">{`${t("details.design")} #${id}`}</h2>
                </section>
                <img src={image} alt={t("details.design")} className="rounded-b-xl lg:w-full" />
            </li>
        </Zoom>
    );

    const renderInputs = (item: string) => (
        <Input register={register} label={item} required errors={errors} key={item} />
    );

    const renderQuantityItem = ({ id, price }: ItemQuantity) => (
        <Zoom triggerOnce key={id}>
            <li
                className={twMerge(
                    "w-72 flex items-start p-4 rounded-lg border-2 border-transparent shadow-2xl transition-all cursor-pointer mb-10 hover:scale-105 lg:mb-4 lg:last:mb-0 lg:hover:scale-100 lg:w-full",
                    activeQuantity === price && "border-primary"
                )}
                onClick={handleChangeQuantity(price)}>
                <div className="flex justify-between">
                    {activeQuantity === price ? (
                        <OrderArrow className="mt-1" />
                    ) : (
                        <OrderEmpty className="mt-1" />
                    )}
                </div>

                <section className="ml-2 w-full">
                    <h2 className="text-shuttle-gray text-base font-normal mb-1 relative flex w-full">
                        <span className="font-semibold text-ebony-clay">{id}</span>&nbsp;
                        <p className="w-full flex justify-between">
                            {t("details.cards")}
                            {id === 400 && (
                                <span className="h-7 px-3 rounded-full bg-primary bg-opacity-10 text-sm text-primary flex justify-center items-center font-normal">
                                    Popular
                                </span>
                            )}
                        </p>
                    </h2>
                    <p className="text-2xl text-ebony-clay font-bold">${price}</p>
                </section>
            </li>
        </Zoom>
    );

    const renderCardType = ({ header, image, size }: ItemCardType) => (
        <Zoom triggerOnce key={size}>
            <li
                className={twMerge(
                    "rounded-lg border-2 border-transparent shadow-2xl transition-all cursor-pointer mb-10 hover:scale-105 lg:mb-4 lg:last:mb-0 lg:hover:scale-100",
                    activeSize === size && "border-primary"
                )}
                onClick={handleActiveSize(size)}>
                <img src={image} alt="" className="rounded-t-lg" />
                <section className="p-4 flex items-start">
                    {activeSize === size ? (
                        <OrderArrow className="mt-1" />
                    ) : (
                        <OrderEmpty className="mt-1" />
                    )}

                    <div className="ml-2">
                        <h2 className="text-ebony-clay text-base font-bold mb-2">
                            {t(`details.${header}`)}
                        </h2>
                        <p className="text-sm text-shuttle-gray font-normal">
                            <span className="text-opacity-60">{t("details.cardSize")} &nbsp;</span>
                            {size}
                        </p>
                    </div>
                </section>
            </li>
        </Zoom>
    );

    useEffect(() => {
        scrollToTop();
        switch (window.location.search) {
            case PackageId.FIRST:
                setActiveSize('3.5" x 2.0"');
                break;
            case PackageId.SECOND:
                setActiveSize('2.56" x 2.56"');
                break;
            case PackageId.THIRD:
                setActiveSize('3.3" x 2.16"');
                break;
            default:
                setActiveSize('3.5" x 2.0"');
                break;
        }
    }, []);

    const orderDetails: OrderTypeItem[] = [
        {
            number: 1,
            header: "chooseDesign",
            content: <ul className={defaultUlStyle}>{designArray.map(renderItem)}</ul>
        },
        {
            number: 2,
            header: "enterInfo",
            content: (
                <form
                    className="w-full flex flex-col pl-10 lg:pl-0"
                    onSubmit={handleSubmit(onSubmit)}>
                    {inputsArray.map(renderInputs)}
                </form>
            )
        },
        {
            number: 3,
            header: "quantity",
            content: <ul className={defaultUlStyle}>{quantityArray.map(renderQuantityItem)}</ul>
        },
        {
            number: 4,
            header: "cardType",
            content: <ul className={defaultUlStyle}>{cardTypeArray.map(renderCardType)}</ul>
        }
    ];

    const renderOrderDetailsItem = ({ number, header, content }: OrderTypeItem) => (
        <li className="mb-8" key={header}>
            <div className="flex items-center mb-5">
                <span className="h-9 rounded-full border border-gray text-xl flex justify-center items-center text-ebony-clay px-3 mr-2">
                    {number}
                </span>
                <h1 className="text-2xl text-ebony-clay font-semibold">{t(`details.${header}`)}</h1>
            </div>
            {content}
        </li>
    );

    return (
        <>
            <Wrapper header={t("details.details")}>
                <ul>{orderDetails.map(renderOrderDetailsItem)}</ul>
            </Wrapper>

            <section className="bg-white w-full sticky bottom-0 border-t border-gray flex justify-center">
                <div className="max-w-360 w-full flex justify-between p-4 lg:flex-col">
                    <div className="lg:mb-2">
                        <h1 className="text-sm text-shuttle-gray mb-1">{t("details.summary")}</h1>
                        <p className="text-3xl font-semibold text-ebony-clay">${activeQuantity}</p>
                    </div>
                    <div className="flex items-center lg:flex-col lg:items-start">
                        <div className="flex items-center lg:mb-4">
                            <input
                                id="default-checkbox"
                                type="checkbox"
                                className="w-4 h-4 border-primary rounded focus:border-transparent cursor-pointer"
                                {...register("terms", { required: true })}
                            />
                            <label
                                htmlFor="default-checkbox"
                                className="ms-2 text-sm font-normal text-shuttle-gray">
                                <Trans
                                    i18nKey="details.readTerms"
                                    components={{
                                        color: <Link to={TERMS} className="text-sm text-primary" />
                                    }}
                                />
                            </label>
                        </div>
                        <Button
                            buttonType={ButtonType.GREEN}
                            className={twMerge(
                                "w-fit ml-10 lg:w-full lg:ml-0 lg:flex lg:justify-center",
                                !isValid && "pointer-events-none opacity-50"
                            )}
                            onClick={handleSubmit(onSubmit)}>
                            {t("details.checkout")}
                        </Button>
                    </div>
                </div>
            </section>
        </>
    );
};
