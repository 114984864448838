import { FC, SVGProps } from "react";

export const HeaderArrow: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" width={16} {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7762 10.3961C11.6437 10.396 11.5167 10.3434 11.423 10.2497C11.3293 10.156 11.2766 10.0289 11.2766 9.89642L11.2766 5.44636L4.58733 12.1356C4.49356 12.2294 4.36638 12.282 4.23377 12.282C4.10116 12.282 3.97399 12.2294 3.88022 12.1356C3.78645 12.0418 3.73377 11.9146 3.73377 11.782C3.73377 11.6494 3.78645 11.5223 3.88022 11.4285L10.5694 4.73925L6.11939 4.73925C6.05229 4.74162 5.98539 4.73045 5.9227 4.70641C5.86001 4.68236 5.8028 4.64594 5.75449 4.5993C5.70618 4.55267 5.66776 4.49678 5.64152 4.43498C5.61528 4.37317 5.60175 4.30671 5.60175 4.23957C5.60175 4.17242 5.61528 4.10596 5.64152 4.04416C5.66776 3.98235 5.70618 3.92646 5.75449 3.87983C5.8028 3.8332 5.86001 3.79677 5.9227 3.77272C5.98539 3.74868 6.05229 3.73751 6.11939 3.73988L11.7762 3.73988C11.9087 3.73996 12.0358 3.79263 12.1295 3.88632C12.2232 3.98002 12.2759 4.10707 12.2759 4.23957L12.2759 9.89642C12.2759 10.0289 12.2232 10.156 12.1295 10.2497C12.0358 10.3434 11.9087 10.396 11.7762 10.3961Z"
            fill="#31D97C"
        />
    </svg>
);
