import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import { navigationLinkContent, privacyContent, supportLink } from "constants/index";
import { NavigationLinkProps, PrivacyProps } from "types/index";
import { scrollIntoElementById } from "utils/scroll-into-element-by-id";
import { SectionId } from "enums/index";
import { twMerge } from "tailwind-merge";
import { Link, useNavigate } from "react-router-dom";
import { HOME_PATH } from "router/constants";

interface Item {
    title: string;
    items: NavigationLinkProps[] | PrivacyProps[] | string[];
}

const itemArray = [
    {
        title: "general",
        items: navigationLinkContent
    },
    {
        title: "policies",
        items: privacyContent
    },
    {
        title: "contacts",
        items: ["2901 W Bluegrass Blvd. Suite 200,<br /> Lehi, UT, 84043"]
    }
];

export const Footer: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = (path: SectionId | string) => () => {
        navigate(path === SectionId.HOME_PAGE ? HOME_PATH : `/#${path}`);
        scrollIntoElementById(path);
    };
    const defaultStyles =
        "text-athens-gray text-sm mb-2 last:mb-0 transition-all hover:text-opacity-65";

    const renderButtons = (item: NavigationLinkProps | PrivacyProps | string) => {
        if (typeof item === "string") {
            return (
                <li className={twMerge(defaultStyles, "flex flex-col")} key={item}>
                    <Trans i18nKey={"custom"}>{item}</Trans>
                    <a className="text-primary underline mt-3" href={`mailto:${supportLink}`}>
                        {supportLink}
                    </a>
                </li>
            );
        }

        if ("name" in item) {
            if (item.name.includes("navigation")) {
                return (
                    <li className={defaultStyles} key={item.name}>
                        <button onClick={handleClick(item.id)}>{t(item.name)}</button>
                    </li>
                );
            } else if (item.name.includes("privacy")) {
                return (
                    <li className={defaultStyles} key={item.name}>
                        <Link to={item.id}>{t(item.name)}</Link>
                    </li>
                );
            }
        }

        return null;
    };

    const renderLinks = (item: Item) => (
        <ul className="lg:mb-4 lg:last:mb-0" key={item.title}>
            <p className="text-sm text-athens-gray text-opacity-40 mb-2">
                {t(`footer.${item.title}`)}
            </p>
            {item.items.map(renderButtons)}
        </ul>
    );

    return (
        <footer className="flex justify-center bg-ebony">
            <section
                aria-label="Footer"
                className="w-full max-w-360 py-20 px-9 mt-2 flex items-start justify-between lg:px-4 lg:py-8 lg:flex-col">
                <Slide triggerOnce>
                    <p className="text-base text-athens-gray lg:mb-4">
                        {process.env.REACT_APP_WEBSITE_TITLE}
                    </p>
                    {itemArray.map(renderLinks)}
                </Slide>
            </section>
        </footer>
    );
};
