import { FC, SVGProps } from "react";

export const ClockIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" width={28} {...props}>
        <g clipPath="url(#clip0_13_793)">
            <path
                d="M14 1.55554C11.5387 1.55554 9.13271 2.2854 7.08623 3.65281C5.03975 5.02022 3.44472 6.96378 2.50282 9.2377C1.56093 11.5116 1.31449 14.0138 1.79466 16.4278C2.27484 18.8418 3.46005 21.0592 5.20044 22.7995C6.94083 24.5399 9.15822 25.7251 11.5722 26.2053C13.9862 26.6855 16.4883 26.439 18.7623 25.4972C21.0362 24.5553 22.9798 22.9602 24.3472 20.9137C25.7146 18.8673 26.4444 16.4613 26.4444 14C26.4444 10.6995 25.1333 7.53422 22.7995 5.20044C20.4658 2.86665 17.3005 1.55554 14 1.55554ZM18.8222 18.0289C18.7657 18.1144 18.6929 18.1879 18.6079 18.2451C18.5229 18.3024 18.4274 18.3423 18.3269 18.3625C18.2265 18.3827 18.123 18.3829 18.0225 18.363C17.9219 18.3432 17.8263 18.3036 17.7411 18.2467L13.1522 15.1355V8.3611C13.1522 8.15482 13.2342 7.95699 13.38 7.81113C13.5259 7.66526 13.7237 7.58332 13.93 7.58332C14.1363 7.58332 14.3341 7.66526 14.48 7.81113C14.6258 7.95699 14.7078 8.15482 14.7078 8.3611V14.3111L18.5967 16.9478C18.6828 17.0037 18.757 17.076 18.815 17.1608C18.873 17.2455 18.9137 17.3408 18.9346 17.4413C18.9556 17.5418 18.9565 17.6455 18.9372 17.7463C18.9179 17.8472 18.8788 17.9432 18.8222 18.0289ZM18.55 6.4011C17.4556 5.74148 16.2315 5.3264 14.9617 5.18435C13.6918 5.0423 12.4062 5.17664 11.1932 5.57815C9.9801 5.97966 8.86825 6.63884 7.9339 7.51046C6.99954 8.38207 6.2648 9.4455 5.78008 10.6278C5.29537 11.8101 5.07215 13.0832 5.12574 14.3599C5.17932 15.6365 5.50846 16.8865 6.09055 18.024C6.67264 19.1615 7.49392 20.1596 8.49803 20.9498C9.50214 21.7401 10.6653 22.3038 11.9078 22.6022L11.6667 23.8233C10.2417 23.4917 8.90574 22.8547 7.75093 21.9565C6.59612 21.0582 5.65 19.9201 4.97787 18.6206C4.30574 17.3211 3.92363 15.8912 3.8579 14.4297C3.79218 12.9681 4.0444 11.5098 4.59717 10.1552C5.14995 8.8006 5.99009 7.58212 7.05961 6.58385C8.12914 5.58557 9.40256 4.83129 10.792 4.37306C12.1814 3.91484 13.6537 3.76359 15.1072 3.92976C16.5608 4.09594 17.961 4.57556 19.2111 5.33554L18.55 6.4011Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_13_793">
                <rect width="28" height="28" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
