import { FC, useEffect } from "react";
import { Wrapper } from "components/common/wrapper.component";
import { Trans, useTranslation } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import { twMerge } from "tailwind-merge";
import { originLink, supportLink } from "constants/index";
import { TERMS } from "router/constants";
import { scrollToTop } from "utils/to-top";

interface LiItem {
    header: string;
    content: JSX.Element;
}

export const ShippingPolicy: FC = () => {
    const { t } = useTranslation();
    const termsLink = originLink + TERMS;
    const defaultStyles = "text-sm text-shuttle-gray font-normal leading-6";

    const shippingItems = [
        {
            header: "options",
            content: <p className={twMerge(defaultStyles, "mt-3")}>{t("shipping.various")}</p>
        },
        {
            header: "free",
            content: <p className={twMerge(defaultStyles, "mt-3")}>{t("shipping.offer")}</p>
        },
        {
            header: "deliver",
            content: (
                <>
                    <p className={twMerge(defaultStyles, "mt-3 mb-4")}>{t("shipping.flat")}</p>
                    <p className={defaultStyles}>{t("shipping.subject")}</p>
                </>
            )
        },
        {
            header: "happens",
            content: <p className={twMerge(defaultStyles, "mt-3 mb-4")}>{t("shipping.delayed")}</p>
        },
        {
            header: "contact",
            content: (
                <p className={twMerge(defaultStyles, "mt-3 mb-4")}>
                    <Trans
                        i18nKey="shipping.question"
                        components={{
                            "email-link": (
                                <a
                                    className="text-primary underline"
                                    href={"mailto:" + supportLink}>
                                    {supportLink}
                                </a>
                            )
                        }}
                    />
                </p>
            )
        }
    ];
    const renderLi = ({ header, content }: LiItem) => (
        <Slide triggerOnce key={header}>
            <li className="font-bold text-xl text-ebony-clay mb-4">
                {t(`shipping.${header}`)}
                {content}
            </li>
        </Slide>
    );

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <Wrapper header="shipping.header" arrow={true}>
            <section>
                <p className={twMerge(defaultStyles, "mb-4 w-9/12 lg:w-full")}>
                    <Trans
                        i18nKey="shipping.shipping"
                        components={{
                            "color-link": (
                                <a className="text-primary underline" href={termsLink}>
                                    {termsLink}
                                </a>
                            )
                        }}
                    />
                </p>
                <p className={twMerge(defaultStyles, "mb-4")}>{t("shipping.carefully")}</p>
                <p className={twMerge(defaultStyles, "mb-4")}>{t("shipping.delivery")}</p>
            </section>

            <ul className="list-none w-9/12 lg:w-full">{shippingItems.map(renderLi)}</ul>
        </Wrapper>
    );
};
