import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    className: string;
}

export const Generated: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const list = t("terms.distribution").split(";");

    const renderLi = (item: string) => (
        <li className={className} key={item}>
            {item}
        </li>
    );

    return (
        <>
            <p className={twMerge(className, "mt-3 mb-4")}>{t("terms.postContent")}</p>
            <ul className="list-disc list-inside mb-4">{list.map(renderLi)}</ul>
            <p className={className}>{t("terms.marketplace")}</p>
        </>
    );
};
