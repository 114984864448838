import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { SectionId } from "enums/index";
import { Zoom } from "react-awesome-reveal";
import { HowItWorksItem } from "types/index";
import { howItWorksArray } from "constants/index";

export const HowItWorks: FC = () => {
    const { t } = useTranslation();
    const renderList = (item: HowItWorksItem) => (
        <li
            className="px-8 py-9 bg-white rounded-lg border-t-4 w-120/4 border-primary transition-all hover:scale-105 hover:shadow-lg lg:w-full lg:mb-4 lg:last:mb-0"
            key={item.step}>
            <div className="bg-gray h-9 px-3 text-xs rounded mb-10 flex justify-center items-center w-fit">
                {t("howItWorks.step", { step: item.step })}
            </div>
            <h1 className="pb-4 mb-10 border-b border-shuttle-gray border-opacity-15 font-bold text-xl text-ebony-clay">
                {t(item.header)}
            </h1>
            <p className="text-ebony-clay text-sm font-normal">{t(item.text)}</p>
        </li>
    );

    return (
        <section className="w-full mt-2 flex justify-center" id={SectionId.HOW}>
            <Zoom triggerOnce>
                <div className="max-w-360 w-screen py-20 px-8 lg:block lg:py-14">
                    <h1 className="text-ebony-clay text-5xl font-semibold min-w-1/3 mb-2">
                        <Trans
                            i18nKey={"howItWorks.how"}
                            components={{ color: <span className="text-primary" /> }}
                        />
                    </h1>
                    <p className="text-base text-shuttle-gray mb-8">
                        <Trans i18nKey={"howItWorks.simple"} />
                    </p>
                    <ul className="flex justify-between lg:flex-col">
                        {howItWorksArray.map(renderList)}
                    </ul>
                </div>
            </Zoom>
        </section>
    );
};
