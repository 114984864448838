import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    className: string;
}

export const Disclamer: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return <p className={twMerge(className, "mt-3 mb-4")}>{t("terms.siteProvided")}</p>;
};
