import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    className: string;
}

export const Information: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <>
            <p className={twMerge(className, "mt-3 mb-4")}>{t("privacyPolicy.variety")}</p>
            <p className={twMerge(className, "mb-4")}>
                <Trans
                    i18nKey="privacyPolicy.automatically"
                    components={{
                        color: <span className="font-semibold" />
                    }}
                />
            </p>
            <p className={twMerge(className, "mb-4")}>{t("privacyPolicy.opportunity")}</p>
            <p className={twMerge(className, "mb-4")}>
                <Trans
                    i18nKey="privacyPolicy.purchase"
                    components={{
                        color: <span className="font-semibold" />
                    }}
                />
            </p>
            <p className={twMerge(className, "mb-4")}>
                <Trans
                    i18nKey="privacyPolicy.reviews"
                    components={{
                        color: <span className="font-semibold" />
                    }}
                />
            </p>
            <p className={twMerge(className, "mb-4")}>
                <Trans
                    i18nKey="privacyPolicy.personal"
                    components={{
                        color: <span className="font-semibold" />
                    }}
                />
            </p>
            <p className={className}>
                <Trans
                    i18nKey="privacyPolicy.informationWithout"
                    components={{
                        color: <span className="font-semibold" />
                    }}
                />
            </p>
        </>
    );
};
