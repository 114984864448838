import { FC } from "react";
import { Trans } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    className: string;
}

export const Dispute: FC<Props> = ({ className }) => {
    return (
        <>
            <p className={twMerge(className, "mt-3 mb-4")}>
                <Trans
                    i18nKey="terms.negotiations"
                    components={{
                        he: <span className="font-semibold" />
                    }}
                />
            </p>
            <p className={twMerge(className, "mt-3 mb-4")}>
                <Trans
                    i18nKey="terms.restrictions"
                    components={{
                        he: <span className="font-semibold" />
                    }}
                />
            </p>
            <p className={twMerge(className, "mt-3 mb-4")}>
                <Trans
                    i18nKey="terms.arbitration"
                    components={{
                        he: <span className="font-semibold" />
                    }}
                />
            </p>
        </>
    );
};
